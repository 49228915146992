.two-col-table {
  padding-top: 1em;
  &-item {
    margin-bottom: .2em;
    padding: .2em 1em;
    border-bottom: 1px solid #eee;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &-label {}
    &-value {
      text-align: right;
    }
  }
}
