@import "variables";

body, button, input {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
}

.login-form {

}

.home-carousel {
  padding: 2em 0;
  & img {
    max-width: 100%;
  }
}
.home-buttons {
  padding: 2em 0 0 0;
}
.home-period-title {
  text-align: center;
  font-size: 2em;
  margin: 2em 0;
}

.home-lineup-description {
  padding: 1em;
  height: 10em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  & button {
    padding: .5em 1em;
    background-color: $red;
    border: 0;
    color: white;
    font-weight: bold;
  }
}

.lookup-players-header {
  padding: 4rem 0 1rem 0;
  font-size: 1em;
  font-weight: bold;
  text-align: center;
}

.player-details {
  &-top {
    padding: 2em;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &-image {
    & img {
      width: 30vw;
    }
  }
  &-name {
    font-size: 1.2em;
    display: flex;
    justify-content: flex-start;
    text-align: center;
    flex-direction: column;
    & > span {
      &:first-child {
        font-weight: bold;
      }
      margin-bottom: .2em;
    }
  }
  &-chart {
    & img {
      max-width: 100%;
    }
  }
}
