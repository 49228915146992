$red: #ec2227;
$lightred: #F1A4A4;
$green: #1e7e34;
$lightgreen: #aceebc;
$gray: #cccccc;
$lightgray: #eeeeee;

$spacing-hx: 10px;
$spacing-x: 20px;
$spacing-15x: 20px;
$spacing-2x: 40px;